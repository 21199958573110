/// <reference path="THOM.global.js" />

/*
@NAMESPACES:
THOM.WISHLIST
*/

/***********************************************************
@NAMESPACES
***********************************************************/

if (typeof THOM.WISHLIST == 'undefined') {
	THOM.WISHLIST = {}
};

/* 
@CLASS: THOM.WISHLIST
@RETURN: oggetto base wishlist
*
* name : nome della wishlist
* days : giorni di validità
* uniqueId : nome della roprietà chiave
*/
THOM.WISHLIST = function (name, days, uniqueId, debug) {
	this.Debug = debug;
	this.Days = days;
	this.Name = name;
	this.UniqueId = uniqueId;
	this.Items = this.GetCookieItems();
	if (this.Debug) console.log('THOM.WISHLIST');
	if (this.Debug) console.log(this);
};

// METHODS

// get items (cookie)
THOM.WISHLIST.prototype.GetCookieItems = function () {
	if (this.Debug) console.log('THOM.WISHLIST.GetCookieItems');
	var res = []; // empty array type
	try {
		var i, x, y, ARRcookies = document.cookie.split(";");
		for (i = 0; i < ARRcookies.length; i++) {
			x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
			y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
			x = x.replace(/^\s+|\s+$/g, "");
			if (x == this.Name) {
				if (typeof y != 'undefined') res = JSON.parse(y); /* dovrebbe essere un array! */
				break;
			}
		} // end for
	} catch (ex) { console.log(ex); }
	return res;
};

// set items (cookie)
THOM.WISHLIST.prototype.SetCookieItems = function (items) {
	if (this.Debug) console.log('THOM.WISHLIST.SetCookieItems');
	if (this.Debug) console.log(items);
	try{
		var expiryDate = new Date();
		expiryDate.setDate(expiryDate.getDate() + this.Days);
		var value = JSON.stringify(items) + ((this.Days == null) ? "" : "; expires=" + expiryDate.toUTCString() + ";path=/");
		document.cookie = this.Name + "=" + value;
	} catch (ex) { console.log(ex); }
};

// return index in wishlist items array
THOM.WISHLIST.prototype.Index = function (value) {
	if (this.Debug) console.log('THOM.WISHLIST.Index');
	var res = -1;
	// rileggo i dati dal cookie per tenermi aggiornato
	this.Items = this.GetCookieItems();
	num = this.Items.length
	for (var i = 0; i < num; i++) {
		if (this.Items[i][this.UniqueId] == value) {
			res = i;
			break;
		}
	}
	return res;
};

// add product to wishlist
THOM.WISHLIST.prototype.Add = function (obj) {
	if (this.Debug) console.log('THOM.WISHLIST.Add');
	var res = false;
	// verifico se hià inserito
	if (this.Index(obj[this.UniqueId]) > -1) {
		if (this.Debug) console.log(obj[this.UniqueId] + ' already exists');
	} else {
		try {
			// rileggo i dati dal cookie per tenermi aggiornato
			this.Items = this.GetCookieItems();
			// aggiungo alla collezione
			this.Items.push(obj);
			// salvo nel cookie
			this.SetCookieItems(this.Items);
			res = true;
		} catch (ex) { console.log(ex); }

	} // end if
	if (this.Debug) console.log(this);
	return res;
};

// remove product from wishlist
THOM.WISHLIST.prototype.Remove = function (value) {
	if (this.Debug) console.log('THOM.WISHLIST.Remove');
	var res = false;
	var i = this.Index(value);
	if (i > -1) {
		try {
			// rileggo i dati dal cookie per tenermi aggiornato
			//this.Items = this.GetCookieItems(); => già this.Index() rilegge items
			// elimino dalla collezione
			this.Items.splice(i, 1);
			// salvo nel cookie
			this.SetCookieItems(this.Items);
			res = true;
		}
		catch (ex) { console.log(ex); }
	}
	if (this.Debug) console.log(this);
	return res;
};

// count products wishlist
THOM.WISHLIST.prototype.Count = function () {
	if (this.Debug) console.log('THOM.WISHLIST.Count');
	res = 0;
	try {
		// rileggo i dati dal cookie per tenermi aggiornato
		this.Items = this.GetCookieItems();
		res = this.Items.length;
	} catch (ex) { console.log(ex); }
	return res;
};