/* oggetto per la gestione degli eventi wishlist nella pagina*/
// eslint-disable-next-line no-undef
icons_wishlist = function (thom_wishlist) {
	/* riferimenti elementi pagina*/
	this.COUNT = $('.wishlist-btn').length > 0 ? $('.wishlist-btn') : null;
	this.BTN_ADD = $('.wishlist-add').length > 0 ? $('.wishlist-add').first() : null;
	this.BTN_REMOVE = $('.wishlist-remove').length > 0 ? $('.wishlist-remove').first() : null;
	this.product_ID = $('.product_dett').length > 0 ? $('.product_dett').first().data('id') : null;
	this.product_NAME = $('.product_dett').length > 0 ? $('.product_dett').first().find('h1').html() : null;
	this.product_IMAGE = $('.product_dett').length > 0 ? $('.product_dett').first().data('image') : null;
	this.product_IMAGEHOVER = $('.product_dett').length > 0 ? $('.product_dett').first().data('imageHover') : null;
	this.product_URL = window.location.href;
	this.product_Wishlist_Dialog = $('#product--wishlist-dialog').length > 0 ? $('#product--wishlist-dialog').first() : null;

	/* inizializza registro eventi */
	this.Init = function () {
		/* binding evento add */
		if (this.BTN_ADD) {
			this.EventAdd();
		}
		/* binding evento remove */
		if (this.BTN_REMOVE) {
			this.EventRemove();
		}
		/* inizializzo elementi pagina */
		this.Refresh();
	};

	/*verifica prodotto in wishlist e seleziona le opportune azioni rispetto agli elementi di pagina */
	this.Refresh = function () {
		if (thom_wishlist.Index(this.product_ID) > -1) {
			if (this.BTN_ADD) {
				this.BTN_ADD.hide(0);
			}
			if (this.BTN_REMOVE) {
				this.BTN_REMOVE.show(0);
			}
		} else {
			if (this.BTN_ADD) {
				this.BTN_ADD.show(0);
			}
			if (this.BTN_REMOVE) {
				this.BTN_REMOVE.hide(0);
			}
		}
		this.PrintCount();
	};

	/* registra l'evento Add per un certo elemento di pagina*/
	this.EventAdd = function () {
		var _this = this;

		_this.BTN_ADD.click(function (e) {
			e.preventDefault();
			var obj = new Object();
			obj.Id = _this.product_ID;
			obj.Name = _this.product_NAME;
			obj.ImageId = _this.product_IMAGE;
			obj.ImageHoverId = _this.product_IMAGEHOVER;
			obj.Url = _this.product_URL;
			// console.log(obj.ImageHoverId);
			_this.Dialog(obj.Name, obj.ImageHoverId);

			/*aggiungo l'oggetto*/
			thom_wishlist.Add(obj);
			_this.Refresh();
		});


	};

	/* registra l'evento Remove per un certo elemento di pagina*/
	this.EventRemove = function () {
		var _this = this;
		_this.BTN_REMOVE.click(function (e) {
			e.preventDefault();
			/*rimuovo oggetto per pt.Id */
			thom_wishlist.Remove(_this.product_ID);
			_this.Refresh();
		});

	};

	/*scrive il numero di elementi nella wishlist in un certo elemento di pagina */
	this.PrintCount = function () {
		if (this.COUNT != null) {
			var num = thom_wishlist.Count();
			// console.log(num)
			this.COUNT.find('.wishlist-count').html(num);
			if (num <= 0) {
				// this.COUNT.hide();
				this.COUNT.removeClass('show')
				this.COUNT.find('.wishlist-count').html('');
			} else {
				// this.COUNT.show();
				this.COUNT.find('.wishlist-count').html(num);
				this.COUNT.addClass('show');
			}
		}
	};


	this.Dialog = function(title, image){
		var _this = this;

		_this.product_Wishlist_Dialog.find('.image img').attr('src', image);
		_this.product_Wishlist_Dialog.find('.tit').text(title);

		_this.product_Wishlist_Dialog.addClass('show');

		setTimeout(function(){
			_this.product_Wishlist_Dialog.removeClass('show');
		}, 2000);

		_this.product_Wishlist_Dialog.find('.close').on('click', function(){
			_this.product_Wishlist_Dialog.removeClass('show');
		});

	}
};


// eslint-disable-next-line no-undef
list_wishlist = function (thom_wishlist) {
	// riferiementi elementi pagina
	this.LIST = $('.wishlist-list').length > 0 ? $('.wishlist-list').first() : null;
	this.LIST_DESCRIPTION = $('.wishlist-empty').length > 0 ? $('.wishlist-empty').first() : null;
	this.COUNT = $('.wishlist-btn').length > 0 ? $('.wishlist-btn').first() : null;
	// inizializza registro eventi
	this.Init = function () {
		// binding evento count
		this.PrintCount();
		// binding evento remove
		this.EventRemove();
	};
	// registra l'evento Remove per certi elementi di pagina
	this.EventRemove = function () {
		var _this = this;
		_this.LIST.find('.item').each(function (i, elem) {
			$(elem).find('.wishlist-remove').click(function (e) {
				e.preventDefault();
				// rimuovo oggetto per pt.Id
				thom_wishlist.Remove($(elem).data('id'));
				$(elem).fadeOut('400', function () {
					$(this).remove();
				});
				_this.PrintCount();

			}); // end click
		}); // end each
	};
	// scrive il numero di elementi nella wishlist in un certo elemento di pagina
	this.PrintCount = function () {
		if (this.COUNT != null) {
			var num = thom_wishlist.Count();
			this.COUNT.find('.wishlist-count').html(num);
			if (num <= 0) {
				// this.COUNT.hide();
				// this.COUNT.find('.wishlist-count').hide().html('');
				this.LIST_DESCRIPTION.fadeIn();
			} else {
				// this.COUNT.parent().show();
				// this.COUNT.find('.wishlist-count').show().html('(' + num + ')');
				this.LIST_DESCRIPTION.hide(0);
			}
		}
	};
};

