// THOM Dropdown
import hoverIntent from "jquery-hoverintent";
import mCustomScrollbar from "malihu-custom-scrollbar-plugin";
import mousewheel from "jquery-mousewheel";

export function thomDropdown () {	

	/* DESKTOP Action */
	var desk_nav = function (elem) {	
		$(elem).unbind("click");
		
		$(elem).hoverIntent({
			over: function () {
				open(elem);
			},
			out: function () {
				close(elem);
			}
		});
	}

	/* MOBILE Action */
	var mob_nav = function (elem) {
		$(elem).addClass('init');
		$(elem).find('.thom-dropdown--label').unbind("click");
		$(elem).find('.thom-dropdown--label').click(function (e) {
			e.preventDefault();
			$('.thom-dropdown').not(elem).removeClass('is-open');

			if ($(elem).hasClass('is-open')) {
				$(elem).removeClass('is-open');
			} else {
				$(elem).addClass('is-open');
			}

			scrollbar(elem);
			return false;
		});

		if($(elem).hasClass('replaceVal')){
			$(elem).find('.thom-dropdown--options-item').on('click', function(){
				$(elem).find('.thom-dropdown--label-item').text($(this).text());  
			});
		}
	}

	/* Open Select */
	var open = function (elem) {
		$(elem).addClass('is-open');
		scrollbar(elem);
	};

	/* Control for scrollbar */
	var scrollbar = function (elem) {
		var elems = $(elem).find('.thom-dropdown--options-item');
		var elems_n = elems.length;
		var elems_H = 0;
		var elems_max = $(elem).data('max');

		elems.not('.thom-search--filter-btn').click(function () {
			close(elem);
		})

		if ($.isNumeric(elems_max) && elems_n > elems_max) {

			if (elems.length > elems_max) {
				elems.each(function (i) {
					elems_H += $(this).outerHeight(true);
					if (! --elems_max) {
						$(elem).find('.thom-dropdown--options').height(elems_H).css('overflow', 'hidden');
					};
				});
			};

			if ($(elem).find('.thom-dropdown--options').hasClass('mCustomScrollbar')) {
				$(elem).find('.thom-dropdown--options').mCustomScrollbar("update");
			} else {
				$(elem).find('.thom-dropdown--options').mCustomScrollbar();
			}
		};
	}

	/* Close Select */
	var close = function (elem) {
		$(elem).removeClass('is-open');
		if ($(elem).find('.mCustomScrollbar')) {
			$(elem).find('.thom-dropdown--options').mCustomScrollbar("destroy");
		}
	};

	// resize
	/* var resize = function (elem) {
		if (window.innerWidth > 768) {
			desk_nav(elem);
		} else {
			mob_nav(elem);
		}
	}; */

	let dropdown = document.getElementsByClassName('thom-dropdown');
	for( var i = 0; i <= dropdown.length; i++) {
		var _ = dropdown[i];

		if (_) {
			/* resize(_);
			$(window).on('resizeend', 250, function () {
				resize(_);
			}); */
			mob_nav(_);
		}
	}

	var newdropdowns = function () {
		let dropdown = $('thom-dropdown').not('.init');
		for( var i = 0; i <= dropdown.length; i++) {
			var _ = dropdown[i];

			if (_) {
				/* resize(_);
				$(window).on('resizeend', 250, function () {
					resize(_);
				}); */
				mob_nav(_);
			}
		}

	}
}
export default thomDropdown
