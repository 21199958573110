/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { EasePack } from 'gsap/EasePack';
import { CustomEase } from 'gsap/CustomEase';
// import imagesLoaded from 'imagesloaded';

gsap.registerPlugin(EasePack, CustomEase);
let tl;
const scrollAnimation = (locoScroll) => {
  
  let desktopEverInit = false, 
      mobileEverInit = false,
      isMobile = false,
      elsToClear = '',
      firstTime = true;
  const breakpointWidth = 640;

  console.log('scrollAnimation')

  ScrollTrigger.refresh();

  const initMobileWebsite = () => {
    // console.log('initMobileWebsite')
    
    isMobile = true;
    document.body.style.height = "unset";
    
    let hero = document.querySelector('.hero');
    let hero_step_2 = document.querySelector('.hero_step_2');
    
    
    // console.log('mobileEverInit = ' + mobileEverInit)
    if(mobileEverInit === false) {
      mobileEverInit = true;

      if(hero){
        gsap.set('.hero-top, .hero-imgs', {
          autoAlpha: 0, 
          y: 100
        });

        // Use callbacks to control the state of the animation
        ScrollTrigger.create({
          trigger: '.hero-intro',
          end: "bottom bottom",
          scroller: ".smooth-scroll",
          invalidateOnRefresh: true,
          scrub: true,
          //once: true,
          // markers: true,
          onEnter: () => {
            //console.log('onEnter');
            videosBlockStart(hero)
          },
          onEnterBack: function(){
            videosBlockStart(hero)
          },
          onLeave: function(){
            videosBlockStop(hero)
          },
          onLeaveBack: function(){
            videosBlockStop(hero)
          }
        });
      }

      if(hero_step_2){
        gsap.set(hero_step_2, {
          autoAlpha: 0, 
          y: 100
        });

        const step_2_anim = gsap.to(hero_step_2, {duration: 1, autoAlpha: 1, y: 0, paused: true});
            
        // Use callbacks to control the state of the animation
        ScrollTrigger.create({
          trigger: hero_step_2,
          end: "bottom bottom",
          scroller: ".smooth-scroll",
          //once: true,
          // markers: true,
          invalidateOnRefresh: true,
          scrub: true,
          onEnter: self => {
            // console.log('onEnter STEP 2');
            if(step_2_anim.progress() === 0) {
              //console.log('onEnter play');
              step_2_anim.play().then(() => self.kill())
            }
          }
        });
      }
      
      const blocksImages = gsap.utils.toArray('.imgWrp');
  
      // Set things up
      gsap.set(blocksImages, {
        autoAlpha: 0,
        y: 100
      });
  
      blocksImages.forEach((image, i) => {
        // console.log('boxes', image);
        // Set up your animation
        let anim = gsap.to(image, {duration: 1, autoAlpha: 1, y: 0, paused: true});
        
        // Use callbacks to control the state of the animation
        ScrollTrigger.create({
          trigger: image,
          end: "bottom bottom",
          scroller: ".smooth-scroll",
          //once: true,
          // markers: true,
          onEnter: self => {
            // console.log('onEnter anim IMAGE ' +  image);
            if(anim.progress() === 0) {
              //console.log('onEnter play');
              anim.play().then(() => {
                self.kill()
                videosBlockStart(image)
              })
            }
          },
          onEnterBack: function(){
            videosBlockStart(image)
          },
          onLeave: function(){
            videosBlockStop(image)
          },
          onLeaveBack: function(){
            videosBlockStop(image)
          }
        });
      });
    }
  }

  const initWebSite = () => {
    isMobile = false;
    
    if(desktopEverInit === false) {
      desktopEverInit = true;
    }
  }
  
  const startMobileWebsite = () => {
    // console.log('startMobileWebsite')

    gsap.to('.hero-top, .hero-imgs', {
      duration: 1, 
      autoAlpha: 1, 
      y: 0
    });
    
  }
  
  const startWebSite = () => {
    console.log('startWebSite')
    
    let animationContent = document.querySelector('.scrollAnim');
    let hero = document.querySelector('.hero');
    let hero_step_2 = animationContent.querySelector('.hero_step_2');
    let blocks = gsap.utils.toArray('.block');
    let totalPixelScrollable = 4 * window.innerWidth + 6 * window.innerHeight;
    let heroImgTiming = "<+=1.5";

    if(hero){
      elsToClear += '.hero,';
    }
    if(hero_step_2){
      elsToClear += '.hero_step_2,';
    }
    if(blocks){
      elsToClear += '.block,';
    }

    tl = gsap.timeline({
      paused: true,
      defaults: { duration: 3, ease: 'none' },
      scrollTrigger: {
        trigger: animationContent,
        scroller: ".smooth-scroll",
        start: 'top top',
        end: 'top+=' + totalPixelScrollable,
        scrub: true,
        pin: true,
        pinSpacing: true,
        invalidateOnRefresh: true,
        anticipatePin: 1,
        // markers: true
      }
    })
    
    // Hero - SET
    if(hero){
      gsap.to(hero, {
        opacity: 1,
        duration: 0.1
      })

      if(hero.querySelector('.hero-bottom')){
        gsap.set('.hero-bottom', {
          opacity:0, 
          yPercent: 10
        })
        elsToClear += '.hero-bottom,'
      }
      if(hero.querySelector('.hero-imgs')){
        gsap.set('.hero-imgs', {
          scale: 1.25, 
          yPercent: 30
        });

        elsToClear += '.hero-imgs,'
      }
      if(hero.querySelector('.hero-bottom')){
        gsap.to('.hero-bottom', {
          duration: 0.6,
          opacity:1,
          yPercent: 0,
          onComplete: function(){
            videosBlockStart(hero)
          }
        })
      }     
    }
    
    // Hero Step 2 - image + text - SET
    if(hero_step_2){
      if(hero_step_2.querySelector('.col_1_img')){
        gsap.set('.hero_step_2 .col_1_img', {y: window.innerHeight, yPercent: 100})

        elsToClear += '.hero_step_2 .col_1_img,'
      }
      if(hero_step_2.querySelector('.col_2')){
        gsap.set('.hero_step_2 .col_2', {yPercent: 100});
      
        elsToClear += '.hero_step_2 .col_2,'
      }

      heroImgTiming = ">-=8";
    }

    // Hero - PLAY [start]    
    if(hero){
      tl
        .to('.hero-txt', {
          yPercent: -50,
          duration: 1.9,
          ease: "power1.in"
        })
        .to(".hero-top", {
          yPercent: -100,
          duration: 1.8,
          ease: "power1.in"
        }, "<")
        .to('.hero-imgs', {
          scale: 1,
          duration: 1.8,
          ease: "power1.out"
        }, "<")
        .to('.hero-imgs', {
          yPercent: 0,
          duration: 1.8,
          ease: "power1.out"
        }, "<")

    }

    // Hero Step 2 - image + text - PLAY
    if (hero_step_2) {
      tl.to('.hero_step_2 .col_2_txt', {
          yPercent: -100,
          y: -window.innerHeight,
          duration: 5
        }, ">-=0.5")

      if(blocks.length != 0){
      
        tl.to('.hero_step_2 .col_1_img', {
            yPercent: -100,
            scale: 1.8,
            y: -window.innerHeight,
            duration: 14
          }, "<-=2")
      }
    }

    // Hero - PLAY [end]
    if(hero && blocks.length != 0){
      tl
        .to('.hero-imgs', {            
          yPercent: -15,
          scale: 1.25,
          ease: CustomEase.create("custom", "M0,0 C0.188,0 0.33,0.175 0.476,0.322 0.74,0.587 0.95,0.91 1,1 "),
          duration: 2.5
        }, heroImgTiming)
        .to('.hero-bottom', {
          height: 0,
          // opacity:0,
          ease: CustomEase.create("custom", "M0,0 C0.188,0 0.33,0.175 0.476,0.322 0.74,0.587 0.95,0.91 1,1 "),
          duration: 2.5
        }, "<")
    }
    
    // BLOCKS
    if(blocks){

      blocks.forEach((block, index) => {

        let allImg = block.querySelectorAll('.imgWrp');
        let subgrid = block.querySelector('.subgrid');
        let txt_1 = block.querySelector('.txtWrp_1');
        let img_1 = block.querySelector('.imgWrp_1');
        let img_2 = block.querySelector('.imgWrp_2');
        let img_3 = block.querySelector('.imgWrp_3');
        let img_4 = block.querySelector('.imgWrp_4');
        let scrollableImg = block.querySelector('.scrollableImg');
        let scrollableImgRight = block.querySelector('.scrollableImgRight');
        let scrollableImgLeft = block.querySelector('.scrollableImgLeft');
        let scrollableTxt = block.querySelector('.scrollableTxt');
        let semiFastScrollableImg = block.querySelector('.semiFastScrollableImg');
        let fastScrollableImg = block.querySelector('.fastScrollableImg');
        let firstTiming = ">+=0.5";

        if(allImg){
          elsToClear += '.imgWrp,'
        }
        if(subgrid){
          elsToClear += '.subgrid,'
        }
        if(txt_1){
          elsToClear += '.txtWrp_1,'
        }
        if(img_1){
          elsToClear += '.imgWrp_1,'
        }
        if(img_2){
          elsToClear += '.imgWrp_2,'
        }
        if(img_3){
          elsToClear += '.imgWrp_3,'
        }
        if(img_4){
          elsToClear += '.imgWrp_4,'
        }
        if(scrollableImg){
          elsToClear += '.scrollableImg,'
        }
        if(scrollableImgRight){
          elsToClear += '.scrollableImgRight,'
        }
        if(scrollableImgLeft){
          elsToClear += '.scrollableImgLeft,'
        }
        if(scrollableTxt){
          elsToClear += '.scrollableTxt,'
        }
        if(semiFastScrollableImg){
          elsToClear += '.semiFastScrollableImg,'
        }
        if(fastScrollableImg){
          elsToClear += '.fastScrollableImg,'
        }

        if($(block).index() == 1){
          firstTiming = ">-=0.7";
        } 

        // if($(block).index() === blocks.length){
        //   // console.log('3 blocchi')
        //   tl.eventCallback("onStart", function(){
        //     // console.log('tl on start')
        //     // $('.scrollAnim').parent().css({
        //     //   'height': totalPixelScrollable - window.innerHeight / blocks.length,
        //     //   'padding-bottom': totalPixelScrollable - window.innerHeight / blocks.length
        //     // })              
        //   });
          
        // }

        // block opacity
        tl
          .to(block, {
            opacity: 1,
            duration: 0.1,
            onStart: function(){
              block.classList.add('anim')
              videosBlockStart(block)
            },
            onReverseComplete: function(){
              block.classList.remove('anim')
              videosBlockStop(block)
            }
          }, firstTiming)

        if(block.classList.contains('block_A')){
          
          gsap.set(allImg, { scale: 1, y: window.innerHeight});
          gsap.set(txt_1, { scale: 1, y: window.innerHeight});

          gsap.set(img_1, { 
            top: "10%",
            left: '40%',
          })
          gsap.set(txt_1, {
            bottom: "20%",
            left: '5%',
          })
          gsap.set(img_2, {
            top: "-5%",
            left: '8%',
          })
          gsap.set(img_3, {
            top: "30%",
            left: '-40%',
          })
          gsap.set(img_4, {
            bottom: 0,
            left: '-50%',
          })
          gsap.set(subgrid, {
            width: "85%",
            height: "100%",
            yPercent: 50
          })

          tl
            // go into viewport
            .to(txt_1, {
              y: 0,
              duration: 0.8,
              ease: "power1.out"
            }, "<")
            .to(img_2, {
              y: 0,
              duration: 1,
              ease: "power1.out",
            }, "<-=0.2")
            .to(img_1, {
              y: 0,
              duration: 1,
              ease: "power1.out",
            }, "<+=0.2")
            .to(img_3, {
              y: 0,
              duration: 0.8,
              ease: "power1.out",
            }, "<+=0.4")
            .to(subgrid, {
              duration: 2,
              height: "auto",
              yPercent: 0,
              ease: "power1.out",
            }, "<")
            .to(img_4, {
              y: 0,
              duration: 1,
              ease: "power1.out",
              onStart: function(){
                block.classList.add('active');
              },
              onReverseComplete: function(){
                block.classList.remove('active');
              }
            }, "<+=0.3")

            // allargo
            .to(img_1, { 
              top: "0%",
              left: "0%",
              scale: .75,
              ease: "power4.out",
              duration: 2
            }, ">+=1")
            .to(txt_1, {
              top: "0%",
              left: "0%",
              scale: .75,
              duration: 1.8,
              ease: "power4.out",
            }, "<")
            .to(img_2, {
              top: "0%",
              left: "0%",
              scale: 1.15,
              ease: "power4.out",
              duration: 2
            }, "<")
            .to(img_3, {
              top: "0%",
              left: "0%",
              scale: .75,
              ease: "power4.out",
              duration: 2
            }, "<")
            .to(img_4, {
              bottom: "0%",
              left: "0%",
              scale: .75,
              ease: "power4.out",
              duration: 2
            }, "<")
            .to(subgrid, {
              width: "100%",
              ease: "power4.out",
              duration: 2
            }, "<")

            // go away
          if(!$(block).is(':last-child')){
          tl
            .to(scrollableImg, {
              y: -window.innerHeight,
              ease: CustomEase.create("custom", "M0,0 C0.188,0 0.33,0.175 0.476,0.322 0.74,0.587 0.95,0.91 1,1 "),
              duration: 4,
              onStart: function(){
                block.classList.remove('active');
              }
            }, ">+=0.2")
            .to(scrollableImgRight, {
              y: -window.innerHeight,
              ease: CustomEase.create("custom", "M0,0 C0.188,0 0.33,0.175 0.476,0.322 0.74,0.587 0.95,0.91 1,1 "),
              duration: 2.5,
            }, "<")
            .to(scrollableTxt, {
              y: -window.innerHeight,
              ease: CustomEase.create("custom", "M0,0 C0.188,0 0.33,0.175 0.476,0.322 0.74,0.587 0.95,0.91 1,1 "),
              duration: 2,
              onReverseComplete: function(){
                block.classList.add('active');
              }
            }, "<")  
            .to(semiFastScrollableImg, {
              y: -window.innerHeight,
              ease: CustomEase.create("custom", "M0,0 C0.188,0 0.33,0.175 0.476,0.322 0.74,0.587 0.95,0.91 1,1 "),
              duration: 3.2,
            }, "<") 
            .to(fastScrollableImg, {
              y: -window.innerHeight,
              ease: CustomEase.create("custom", "M0,0 C0.188,0 0.33,0.175 0.476,0.322 0.74,0.587 0.95,0.91 1,1 "),
              duration: 2.4,
            }, "<")
            .to(subgrid, {
              duration: 1.8,
              yPercent: -25,
              ease: CustomEase.create("custom", "M0,0 C0.188,0 0.33,0.175 0.476,0.322 0.74,0.587 0.95,0.91 1,1 "),
            }, "<")
          }
            
        }else if(block.classList.contains('block_B')){

          gsap.set(allImg, { scale: 1, x: window.innerWidth});
          gsap.set(txt_1, { scale: 1, x: window.innerWidth});

          gsap.set(img_1, { 
            left: "40%", 
            bottom: "20%",
          })
          gsap.set(txt_1, {
            left: "15%",
            top: "20%",
          })
          gsap.set(img_2, { 
            left: "10%", 
            top: "5%"
          })
          gsap.set(img_3, { 
            right: "40%", 
            top: "25%"
          })
          gsap.set(img_4, { 
            right: "50%", 
            bottom: "0%"
          })
          gsap.set(subgrid, {
            width: "85%",
            xPercent: 50
          })

          tl
            // go into viewport
            .to(img_2, {
              x: 0,
              duration: 1,
              ease: "power1.out"
            }, "<")
            .to(img_3, {
              x: 0,
              duration: 0.8,
              ease: "power1.out",
            }, "<+=0.8")
            .to(subgrid, {
              duration: 2,
              xPercent: 0,
              ease: "power1.out",
            }, "<")
            .to(txt_1, {
              x: 0,
              duration: 1.3,
              ease: "power1.out",
            }, "<+=0.2")
            .to(img_4, {
              x: 0,
              duration: 1,
              ease: "power1.out",
            }, "<+=0.3")
            .to(img_1, {
              x: 0,
              duration: 1,
              ease: "power1.out",
              onStart: function(){
                block.classList.add('active');
              },
              onReverseComplete: function(){
                block.classList.remove('active');
              }
            }, "<+=0.2")
          
            // allargo
            .to(img_1, { 
              left: "0%", 
              bottom: "0%",
              scale: .75,
              ease: "power4.out",
              duration: 2
            }, ">+=1")
            .to(txt_1, {
              left: "0%", 
              top: "0%",
              scale: .75,
              duration: 1.8,
              ease: "power4.out",
            }, "<")
            .to(img_2, { 
              left: "0%", 
              top: "5%",
              scale: 1.15,
              ease: "power4.out",
              duration: 2
            }, "<")
            .to(img_3, { 
              right: "0%", 
              top: "0%",
              scale: .75,
              ease: "power4.out",
              duration: 2
            }, "<")
            .to(img_4, { 
              right: "0%", 
              bottom: "0%",
              scale: .75,
              ease: "power4.out",
              duration: 2
            }, "<")
            .to(subgrid, {
              width: "100%",
              ease: "power4.out",
              duration: 2
            }, "<")

            // go away
            if(!$(block).is(':last-child')){
            tl
            .to(scrollableImg, {
              y: -window.innerHeight,
              ease: CustomEase.create("custom", "M0,0 C0.188,0 0.33,0.175 0.476,0.322 0.74,0.587 0.95,0.91 1,1 "),
              duration: 4,
              onStart: function(){
                block.classList.remove('active');
              }
            }, ">+=0.2")
            .to(scrollableImgLeft, {
              y: -window.innerHeight,
              ease: CustomEase.create("custom", "M0,0 C0.188,0 0.33,0.175 0.476,0.322 0.74,0.587 0.95,0.91 1,1 "),
              duration: 2.5,
            }, "<")
            .to(scrollableTxt, {
              y: -window.innerHeight,
              ease: CustomEase.create("custom", "M0,0 C0.188,0 0.33,0.175 0.476,0.322 0.74,0.587 0.95,0.91 1,1 "),
              duration: 2,
              onReverseComplete: function(){
                block.classList.add('active');
              }
            }, "<")  
            .to(semiFastScrollableImg, {
              y: -window.innerHeight,
              ease: CustomEase.create("custom", "M0,0 C0.188,0 0.33,0.175 0.476,0.322 0.74,0.587 0.95,0.91 1,1 "),
              duration: 2.8,
            }, "<") 
            .to(fastScrollableImg, {
              y: -window.innerHeight,
              ease: CustomEase.create("custom", "M0,0 C0.188,0 0.33,0.175 0.476,0.322 0.74,0.587 0.95,0.91 1,1 "),
              duration: 2.4,
            }, "<")
            .to(subgrid, {
              duration: 1.8,
              yPercent: -25,
              ease: CustomEase.create("custom", "M0,0 C0.188,0 0.33,0.175 0.476,0.322 0.74,0.587 0.95,0.91 1,1 "),
            }, "<")
          }              
        }else if(block.classList.contains('block_C')){

          gsap.set(allImg, { scale: 1, y: window.innerHeight});
          gsap.set(txt_1, { scale: 1, y: window.innerHeight});

          gsap.set(img_1, { 
            left: "20%",
            top: "10%"
          })
          gsap.set(img_2, { 
            left: "30%",
            bottom: "0%"
          })
          gsap.set(img_3, { 
            left: "-5%",
            top: "-5%"
          })
          gsap.set(txt_1, {
            right: "15%",
            top: "10%"
          })
          gsap.set(img_4, { 
            right: "40%",
            bottom: "5%"
          })
          gsap.set(subgrid, {
            width: "85%",
            height: "100%",
            yPercent: 50
          })

          tl
            // go into viewport
            .to(txt_1, {
              y: 0,
              duration: 0.8,
              ease: "power1.out"
            }, "<")
            .to(img_2, {
              y: 0,
              duration: 1,
              ease: "power1.out",
            }, "<-=0.2")
            .to(img_1, {
              y: 0,
              duration: 1,
              ease: "power1.out",
            }, "<+=0.2")
            .to(img_3, {
              y: 0,
              duration: 0.8,
              ease: "power1.out",
            }, "<+=0.4")
            .to(subgrid, {
              duration: 2,
              height: "auto",
              yPercent: 0,
              ease: "power1.out",
            }, "<")
            .to(img_4, {
              y: 0,
              duration: 1,
              ease: "power1.out",
              onStart: function(){
                block.classList.add('active');
              },
              onReverseComplete: function(){
                block.classList.remove('active');
              }
            }, "<+=0.3")

            // allargo
            .to(img_1, { 
              left: "0%",
              top: "0%",
              scale: .75,
              ease: "power4.out",
              duration: 2
            }, ">+=0.8")
            .to(img_2, { 
              left: "0%",
              bottom: "0%",
              scale: .75,
              ease: "power4.out",
              duration: 0.8
            }, "<")
            .to(img_3, { 
              left: "0%",
              top: "0%",
              scale: 1.15,
              ease: "power4.out",
              duration: 1.2
            }, "<")
            .to(txt_1, {
              right: "0%",
              top: "0%",
              scale: .75,
              ease: "power4.out",
              duration: 1.5
            }, "<")
            .to(img_4, { 
              right: "0%",
              bottom: "0%",
              scale: .75,
              ease: "power4.out",
              duration: 0.8
            }, "<")
            .to(subgrid, {
              width: "100%",
              ease: "power4.out",
              duration: 2
            }, "<")

            // go away
          if(!$(block).is(':last-child')){
          tl
            .to(scrollableImg, {
              y: -window.innerHeight,
              ease: CustomEase.create("custom", "M0,0 C0.188,0 0.33,0.175 0.476,0.322 0.74,0.587 0.95,0.91 1,1 "),
              duration: 4,
              onStart: function(){
                block.classList.remove('active');
              }
            }, ">+=0.2")
            .to(scrollableImgLeft, {
              y: -window.innerHeight,
              ease: CustomEase.create("custom", "M0,0 C0.188,0 0.33,0.175 0.476,0.322 0.74,0.587 0.95,0.91 1,1 "),
              duration: 2.5,
            }, "<")
            .to(scrollableTxt, {
              y: -window.innerHeight,
              ease: CustomEase.create("custom", "M0,0 C0.188,0 0.33,0.175 0.476,0.322 0.74,0.587 0.95,0.91 1,1 "),
              duration: 2,
            }, "<")  
            .to(semiFastScrollableImg, {
              y: -window.innerHeight,
              ease: CustomEase.create("custom", "M0,0 C0.188,0 0.33,0.175 0.476,0.322 0.74,0.587 0.95,0.91 1,1 "),
              duration: 2.8,
            }, "<") 
            .to(fastScrollableImg, {
              y: -window.innerHeight,
              ease: CustomEase.create("custom", "M0,0 C0.188,0 0.33,0.175 0.476,0.322 0.74,0.587 0.95,0.91 1,1 "),
              duration: 2.4,
              onReverseComplete: function(){
                block.classList.add('active');
              }
            }, "<")
            .to(subgrid, {
              duration: 1.8,
              yPercent: -25,
              ease: CustomEase.create("custom", "M0,0 C0.188,0 0.33,0.175 0.476,0.322 0.74,0.587 0.95,0.91 1,1 "),
            }, "<")
          }
        }          
      });
    }

    console.log('finish all animations')
    
    // ScrollTrigger.refresh();

    setTimeout(function(){
      locoScroll.update();
    }, 50);
  }

  // video start
  const videosBlockStart = (block) => {
    var medias = block.querySelectorAll('.media');
    medias.forEach((media, index) => {
      if(media.querySelector('.video')){
        var videoElement = media.querySelector('.video');
        var videoSrc = videoElement.dataset.src;

        if(media.classList.contains('is-playing')){
          videoElement.play();
        }else{
          videoElement.src = videoSrc;
          videoElement.load();
          videoElement.addEventListener('canplay', (e) => {
            media.classList.add('is-playing');
            videoElement.play();
          });
        }
      }
    })
  }

  // video pause
  const videosBlockStop = (block) => {
    var medias = block.querySelectorAll('.media');
    medias.forEach((media, index) => {
      if(media.querySelector('.video')){
        var videoElement = media.querySelector('.video');
        videoElement.pause();
      }
    })
  }

  /***********************************/
  /********** Preload stuff **********/

  // Preload images
  const preloadImages = () => {
    // console.log('preloadImages')
    return new Promise((resolve, reject) => {
      // imagesLoaded(document.querySelectorAll(".scrollAnim img"), resolve);
      resolve()
    });
  };

  const resizeStuff = () => {
    // console.log('preloadresizeStuffImages')
    // console.log('resizeStuff tl : ', tl);
    if (tl) {
      clearScene(tl);
    }
    if (breakpointWidth < window.innerWidth) {
      console.log('resizeStuff desk')
      initWebSite();
      startWebSite();
    } else if (isMobile === false) {
      // console.log('resizeStuff isMobile')
      isMobile = true;
      // ScrollTrigger.defaults({ scroller: window });
      initMobileWebsite();
      startMobileWebsite();
      //to mobile stuff
    }
  };

  function clearScene(timeline) {
    console.log('clearScene')

    var targets = timeline.getChildren();
    for (var i = 0; i < targets.length; i++) {
      if (targets[i].target != null && targets[i].target != undefined) {
        gsap.set(targets[i].target.selector, { 
          clearProps: "all"
         });
      }
    }
    // if (elsToClear) {
    //   gsap.set(elsToClear, { clearProps: "all" });
    // }
    if (timeline.scrollTrigger) {
      timeline.scrollTrigger.kill(true);
    }
    timeline.pause(0).kill(true);
  }

  $("#spinner_timeline").addClass("show");

  function loadingTimeline() {
    console.log('loadingTimeline')

    // let loadingTl = gsap.timeline({
    //   repeat: -1, 
    //   delay: .75
    // });
    
    // console.log(siteReady)
    // $("#spinner_timeline").addClass("show");
    // loadingTl.to('#spinner_timeline', {
    //   opacity: 1,
    //   zIndex: 1000
    // })
    // loadingTl.call(() => {
    //   console.log('loadingTl CALL')
      
    //   if(siteReady) {
    //     console.log('site IS Ready')
    //     loadingTl.kill();
    //     $("#spinner_timeline").removeClass("show");
    //     gsap.to('.scrollAnim', {
    //       opacity: 1,
    //       duration: 1
    //     })
    //     runSite();
    //   } else {
    //     console.log('site NO Ready')
    //   }
    // })

    if(siteReady) {
      console.log('site IS Ready')
      // loadingTl.kill();
      $("#spinner_timeline").removeClass("show");
      gsap.to('.scrollAnim', {
        opacity: 1,
        duration: 1
      })
      runSite();
    } 
  }

  function runSite() {    
    if (breakpointWidth < window.innerWidth) {
      console.log('runSite desktop')
      startWebSite();
    } else {
      // console.log('runSite mobile');
      startMobileWebsite();
    }
    document.body.classList.remove("loading");
  }

  let imgLoaded = false,
    domLoaded = false,
    siteReady = false;

  console.log('DOMContentLoaded done');
  domLoaded = true;
  // if (imgLoaded) {
  //   siteReady = true;
  // } 

  if (breakpointWidth < window.innerWidth) {
    initWebSite();
  } else {
    initMobileWebsite();
  }

  preloadImages().then(() => {
    console.log('preloadImages done');
    // locoScroll.update();
    // ScrollTrigger.refresh();

    imgLoaded = true;
    if (domLoaded) {
      siteReady = true;
    }

    loadingTimeline();
  });

  window.addEventListener("resize", resizeStuff);

}

export { scrollAnimation };