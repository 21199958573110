/*
	@NAMESPACES:
	THOM
	THOM.GLOBAL
	THOM.ACTION
*/

// NAMESPACE => (in pratica è un oggetto che serve per creare un contesto nelle quali var e function esistono)

/***********************************************************
@NAMESPACES
***********************************************************/

if (typeof THOM == 'undefined') {
	THOM = {
		GLOBAL: {},
		ACTION: {
			RESPONSE: {}
		}
	}
};

/***********************************************************
@NAMESPACE: THOM
***********************************************************/

/*
@FUNCTION: Chiamata iniziale
*/
THOM.Init = function (culture) {
	alert("top")
	THOM.GLOBAL.culture = culture;
}

/***********************************************************
@NAMESPACE: THOM.GLOBAL
***********************************************************/

// GLOBAL VARS default values
THOM.GLOBAL.culture = null;


/***********************************************************
@NAMESPACE: THOM.ACTION
***********************************************************/

// JSON RESPONSE STATUS

THOM.ACTION.RESPONSE.statusEnum = function() {
	var values = {
		success: 0,
		noPermission: 1,
		contentTypeNotEnabled: 4,
		genericError: 5,
		modelStateError: 6,
		notAvailable: 7,
		extensionNotAllowed: 8,
		schemaErrors: 9,
		fileLoadException: 10
	};
	return values;
};

/***********************************************************
@NAMESPACE: JSON
***********************************************************/
/*
 * Usage:
 *
 *  http://www.sitepoint.com/javascript-json-serialization/
 *
 * // serialize a JavaScript object to a JSON string
 * var str = JSON.stringify(object);
 *
 * // de-serialize a JSON string to a JavaScript object
 * var obj = JSON.parse(str);
 */


var JSON = JSON || {};

// implement JSON.stringify serialization
JSON.stringify = JSON.stringify || function (obj) {

	var t = typeof (obj);
	if (t != "object" || obj === null) {
		// simple data type
		if (t == "string") obj = '"' + obj + '"';
		return String(obj);
	}
	else {
		// recurse array or object
		var n, v, json = [], arr = (obj && obj.constructor == Array);
		for (n in obj) {
			v = obj[n]; t = typeof (v);

			if (t == "string") v = '"' + v + '"';
			else if (t == "object" && v !== null) v = JSON.stringify(v);

			json.push((arr ? "" : '"' + n + '":') + String(v));
		}
		return (arr ? "[" : "{") + String(json) + (arr ? "]" : "}");
	}
};
// implement JSON.parse de-serialization
JSON.parse = JSON.parse || function (str) {
	if (str === "") str = '""';
	eval("var p=" + str + ";");
	return p;
};